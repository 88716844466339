import React from "react"
import { Link } from "gatsby"

const AboutSubmenu = () => {
  return (
    <>
      <li><Link activeClassName="isActive" data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="What is Neuroblastoma" to="/what-is-neuroblastoma/">What is Neuroblastoma? <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link activeClassName="isActive" data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="How is Neuroblastoma Diagnosed" partiallyActive={true} to="/what-is-neuroblastoma/how-is-it-diagnosed/">How is Neuroblastoma Diagnosed? <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link activeClassName="isActive" data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Treatment and Clinical Trials" partiallyActive={true} to="/what-is-neuroblastoma/clinical-trials/">Treatment and Clinical Trials <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link activeClassName="isActive" data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Understanding Clinical Trials" partiallyActive={true} to="/what-is-neuroblastoma/clinical-trial-results/">Understanding Clinical Trials <span className="np-arrow">&rsaquo;</span></Link></li>
      <li><Link activeClassName="isActive" data-gtm-event-category="Top Carousel" data-gtm-event-action="Click" data-gtm-event-label="Neuroblastoma Research" partiallyActive={true} to="/what-is-neuroblastoma/research/">Neuroblastoma Research <span className="np-arrow">&rsaquo;</span></Link></li>
    </>
  )
}

export default AboutSubmenu
